import { RiLinkTag } from '../../Components/RiLinkTag/RiLinkTag';
import { RiLinkUtils } from '../../Utils/RiLinkUtils';
import { RiStringUtils } from '../../Utils/RiStringUtils';
import './RiFooter.scss';

export interface IRiFooterProps {
    handleClickDownload: () => void;
    handleClickPrivacy: () => void;
}

export const RiFooter = (props: IRiFooterProps): JSX.Element => {
    return (
        <div className='footer-container'>

            {/* FOOTER HEADLINE */}
            <h1 className='thank-you-footer'><strong>❤️ Thank you</strong></h1>

            {/* LINK TAGS */}
            <div className='footer-tags'>

                {/* DOWNLOAD LINK */}
                <RiLinkTag
                    text={RiStringUtils.btnDownload}
                    handler={props.handleClickDownload}
                />

                {/* REACH OUT*/}
                <RiLinkTag
                    text={RiStringUtils.btnContact}
                    handler={() => { RiLinkUtils.contactRek() }}
                />

            </div>


            {/* PRODUCT HUNT */}
            <a href="https://www.producthunt.com/posts/rek-it?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-rek-it" target="_blank">
                <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=327456&theme=light" alt="Rek it - Send and receive recommendations between friends | Product Hunt" style={{ paddingTop: 20, width: 250, height: 54 }} width="250" height="54" />
            </a>

            {/* COPYRIGHT */}
            <div className='footer-links'>
                <span className='grey-text footer-copyright'>Copyright ©2021 Rek it.</span>
                <span className='grey-text footer-copyright'><a onClick={props.handleClickPrivacy}>Privacy Policy</a></span>
            </div>

        </div>
    )
}